<template>
    <form class="card" @submit.prevent.stop="save()">
        <div class="row">
            <!-- firstname-->
            <div class="form-group col-md-6">
                <div class="control">
                    <label for="middleName"> Firstname </label>
                    <input
                        v-model="formData.firstname"
                        v-validate="'required'"
                        type="text"
                        class="form-control"
                        placeholder="firstname"
                        name="firstname"
                    >
                    <span class="text-danger">{{ errors.first("firstname") }}</span>
                </div>
            </div>
            <!-- End of firstname-->

            <!-- lastname-->
            <div class="form-group col-md-6">
                <div class="control">
                    <label for="middleName"> Lastname </label>
                    <input
                        v-model="formData.lastname"
                        v-validate="'required'"
                        type="text"
                        class="form-control"
                        placeholder="Doe"
                        name="lastname"
                    >
                    <span class="text-danger">{{ errors.first("lastname") }}</span>
                </div>
            </div>
            <!-- End of firstname-->

            <!-- phone-->
            <div class="form-group col-md-6">
                <div class="control">
                    <label for="middleName"> phone </label>
                    <input
                        v-model="formData.phone"
                        v-validate="'required'"
                        type="text"
                        class="form-control"
                        placeholder="000-000-000"
                        name="phone"
                    >
                    <span class="text-danger">{{ errors.first("phone") }}</span>
                </div>
            </div>
            <!-- End of phone-->

            <!-- email -->
            <div class="form-group col-md-6">
                <div class="control">
                    <label for="middleName"> email </label>
                    <input
                        v-model="formData.email"
                        v-validate="'required|email'"
                        type="text"
                        class="form-control"
                        placeholder="user@example.com"
                        name="email"
                    >
                    <span class="text-danger">{{ errors.first("email") }}</span>
                </div>
            </div>
            <!-- End of email-->

            <!-- leads owner -->
            <div class="form-group col-md-6">
                <label for="rotations"> Lead Owner </label>
                <resource-selector
                    v-model="formData.leadsOwner"
                    v-validate="'required'"
                    :class="{invalid: errors.first('leadsOwner')}"
                    :resource="formData.leadsOwner"
                    name="leadsOwner"
                    :custom-label="userLabel"
                    :searchable-by="['firstname','lastname', 'displayname' , 'id']"
                    resource-url="/users"
                    :disabled="disabled"
                    resource-id="id"
                    placeholder="Select a Lead Owner"
                />
                <span class="text-danger">{{ errors.first("leadsOwner") }}</span>
            </div>
            <!-- End of leads owner -->

            <div class="col-md-6 offset-md-6">
                <div class="actions d-flex justify-content-end">
                    <button class="btn btn-danger" @click.prevent="$emit('cancel')">
                        Cancel
                    </button>
                    <button class="btn btn-primary" :disabled="isLoading" @click="save()">
                        {{ createButtonText }}
                    </button>
                </div>
            </div>
        </div>
    </form>
</template>

<script>
import ResourceSelector from "@c/resource-selector";

export default {
    components: {
        ResourceSelector
    },
    props: {
        formData: {
            type: Object,
            default() {
                return {}
            }
        }
    },
    data() {
        return {
            isLoading: false,
            endpoint: "/leads"
        }
    },
    computed: {
        createButtonText() {
            return this.formData.id ? "Save Changes" : "Create"
        }
    },
    methods: {
        userLabel(user) {
            return user ? `${user.firstname || ""} ${user.lastname || ""}` : ""
        },
        onSuccess(record) {
            let title = "Created";
            let text = "A new Lead has been created"

            if (this.formData.id) {
                title = "Updated";
                text = "The changes has been saved"
            }

            this.$notify({
                title,
                text,
                type: "success"
            });
            this.$emit("saved", record);
        },

        async save() {
            const isValid = await this.$validator.validateAll()
            let method = "POST";
            let url = this.endpoint;

            if (!isValid) {
                this.focusFirstError(false)
                return;
            }

            const formData = { ...this.formData };
            formData.leads_owner_id = this.formData.leadsOwner.id
            delete formData.leadsOwner

            if (formData.id) {
                method = "PUT"
                url += `/${formData.id}`
            }

            if (!this.isLoading) {
                this.isLoading = true;
                axios({
                    url: url,
                    data: formData,
                    method: method
                })
                    .then(({ data }) => {
                        this.isLoading = false;
                        this.onSuccess(data);
                    })
                    .catch((error) => {
                        this.$notify({
                            title: "Error",
                            text: error.response ? error.response.data.errors.message : "Error",
                            type: "error"
                        });
                        return false;
                    })
                    .finally((result) => {
                        this.isLoading = false;
                        return result;
                    })
            }

        }

    }
}
</script>

<style>

</style>
