<template>
    <div class="container-fluid container-fixed-lg leads">
        <h1 class="section-title p-l-10">
            LEADS
        </h1>
        <div v-if="isEditing" class="row mb-2">
            <div class="col">
                <div class="client-name">
                    <h2>
                        {{ leadName }}
                        <button
                            v-if="!editMode"
                            class="btn btn-primary rounded-pill pl-4 pr-4 ml-4 btn-custom-pill"
                            @click.prevent="editMode = !editMode"
                        >
                            <i class="fa mr-2 fa-pencil" />
                        </button>
                    </h2>
                    <span>Contact pipeline <strong><i class="far fa-calendar-alt" /> {{ startDate }} </strong></span>
                </div>
            </div>
            <div class="col-auto">
                <div class="actions">
                    <span v-if="+formData.status" class="badge badge-success d-flex justify-content-center align-items-center" :class="[isWon ? 'badge-success' : 'badge-danger']">
                        {{ statusText }}
                    </span>
                    <button
                        v-if="isOpen"
                        class="btn btn-danger btn-block"
                        :disabled="isLoading"
                        @click="openConfirmLost()"
                    >
                        Lost
                    </button>
                    <button
                        v-if="isOpen"
                        class="btn btn-primary btn-block"
                        :disabled="isLoading"
                        @click="updateStatus({ status: STATUS_WON })"
                    >
                        Won!
                    </button>
                    <button
                        v-if="!isOpen"
                        class="btn btn-primary btn-block"
                        :disabled="isLoading"
                        @click="updateStatus({ status: STATUS_PENDING })"
                    >
                        Re-Open Lead
                    </button>
                </div>
            </div>
        </div>
        <div v-if="!isEditing || editMode" class="row">
            <div class="col-md-12">
                <leads-form
                    :form-data="formData"
                    :status-list="leadStatus"
                    @saved="onSaved"
                    @cancel="onCancel"
                />
            </div>
        </div>
        <div v-if="isEditing" class="row">
            <div class="col">
                <stages-box :days="2" />
            </div>
        </div>
        <div v-if="isEditing" class="row">
            <div class="col-3">
                <contact-box :form-data="formData" />
                <!-- <association-box /> -->
            </div>
            <div class="col">
                <activities-box :form-data="formData" @message-saved="addMessage" />
                <planned-activities :planned-activities="messages" />
                <past-activities :past-activities="pastActivities" />
            </div>
        </div>

        <modal
            :draggable="true"
            :adaptive="true"
            :scrollable="true"
            name="lost-lead-modal"
            height="auto"
        >
            <lost-lead-modal @saved="updateStatus" />
        </modal>
    </div>
</template>

<script>
import moment from "moment";
import LeadsForm from "./form";

export default {
    name: "Leads",
    components: {
        contactBox: () => import(/* webpackChunkName: "contact-box"*/ "./contact-box"),
        // associationBox: () => import(/* webpackChunkName: "association-box"*/ "./association-box"),
        stagesBox: () => import(/* webpackChunkName: "stages-box"*/ "./stages-box"),
        activitiesBox: () => import(/* webpackChunkName: "activities-box"*/ "./activities-box"),
        plannedActivities: () => import(/* webpackChunkName: "planned-activities"*/ "@c/feed/planned-activities"),
        pastActivities: () => import(/* webpackChunkName: "past-activities"*/ "@c/feed/past-activities"),
        LostLeadModal: () => import(/* webpackChunkName: "lost-lead-modal"*/ "./lost-lead-modal"),
        LeadsForm
    },
    props: {
        isEditing: {
            type: Boolean
        }
    },
    data() {
        return {
            endpoint: "leads",
            editMode: false,
            leadStatus: [],
            isLoading: false,
            STATUS_PENDING: 0,
            STATUS_WON: 1,
            STATUS_LOST: 2,
            formData: {
                leadsOwner: null
            },
            messages: [],
            plannedActivities: [
                {
                    id: 1,
                    type: "call",
                    icon: "fas fa-phone",
                    title: "Get approval",
                    dueTime: "12 hours",
                    contact: "Johnito Doesis"
                }
            ],
            pastActivities: {
                all: [
                    {
                        id: 1,
                        type: "call",
                        icon: "fas fa-phone",
                        title: "Get approval",
                        dueTime: "12 hours",
                        contact: "Johnito Doesis"
                    }
                ],
                call: [
                    {
                        id: 1,
                        type: "call",
                        icon: "fas fa-phone",
                        title: "Get approval",
                        dueTime: "12 hours",
                        contact: "Johnito Doesis"
                    }
                ],
                text: [
                    {
                        id: 2,
                        type: "text",
                        icon: "fas fa-sms",
                        title: "Get approval",
                        dueTime: "12 hours",
                        contact: "Johnito Doesis"
                    }
                ],
                email: [
                    {
                        id: 3,
                        type: "email",
                        icon: "fas fa-envelope",
                        title: "Get approval",
                        dueTime: "12 hours",
                        contact: "Johnito Doesis"
                    }
                ],
                chat: [
                    {
                        id: 4,
                        type: "chat",
                        icon: "fas fa-headset",
                        title: "Get approval",
                        dueTime: "12 hours",
                        contact: "Johnito Doesis"
                    }
                ],
                notes: [
                    {
                        id: 5,
                        type: "notes",
                        icon: "fas fa-clipboard",
                        title: "Get approval",
                        dueTime: "12 hours",
                        contact: "Johnito Doesis"
                    }
                ],
                activity: [
                    {
                        id: 6,
                        type: "activity",
                        icon: "fas fa-calendar-day",
                        title: "Get approval",
                        dueTime: "12 hours",
                        contact: "Johnito Doesis"
                    }
                ],
                files: [
                    {
                        id: 7,
                        type: "upload",
                        icon: "fas fa-cloud-upload-alt",
                        title: "Get approval",
                        dueTime: "12 hours",
                        contact: "Johnito Doesis"
                    }
                ]
            }
        };
    },
    computed: {
        leadName() {
            return `${this.formData.firstname || "N/A"}  ${this.formData.lastname || ""}`
        },
        isWon() {
            return this.formData.status == 1
        },

        statusText() {
            return this.isWon ? "Won" : "Lost";
        },

        startDate() {
            return moment(this.formData.created_at).format("MMMM DD, YYYY");
        },
        creationMode() {
            return this.isEditing || this.editMode;
        },
        isOpen() {
            return !this.formData.status;
        }
    },
    watch: {
        "$route.params.id": {
            handler(id) {
                if (id) {
                    this.getRecord();
                    return;
                }
            },
            immediate: true
        }
    },
    mounted() {
        this.getStatus();
    },
    methods: {
        onSaved(record) {
            if (!this.isEditing) {
                this.$router.replace({
                    name: "edit-resource",
                    params: {
                        resource: "leads",
                        id: record.id
                    } });
            } else {
                this.getRecord(record.id);
                this.editMode = false;
            }
        },

        onCancel() {
            if (!this.isEditing) {
                this.$router.replace({
                    name: "browse",
                    params: {
                        resource: "leads"
                    } });
            } else {
                this.editMode = false;
            }
        },

        getStatus() {
            axios({
                url: `leads-status`
            }).then(({ data }) => {
                if (data) {
                    this.leadStatus = data
                }
            })
        },

        getRecord(id) {
            axios({
                url: `${this.endpoint}/${id || this.$route.params.id}?relationships=leadsOwner`
            }).then(async({ data }) => {
                if (data) {
                    const formData = { ... data }
                    formData.leadsOwner = formData.leads_owner_id ? await axios(`users/${formData.leads_owner_id}`).then(({ data }) => data) : null;
                    this.formData = { ...formData };
                    this.getMessages(data.uuid);
                }
            })
        },

        getMessages(uuid) {
            axios({
                url: `/leads/${uuid}/messages`
            }).then(({ data }) => {
                if (data) {
                    this.messages = data
                }
            })
        },
        addMessage(message) {
            this.messages.unshift(message)
        },

        openConfirmLost() {
            this.$modal.show("lost-lead-modal");
        },

        async updateStatus(formData) {
            const statusMessage = [
                "Status updated",
                "Congrats lead won!",
                "The lead has been lost"
            ];

            if (!this.isLoading) {
                this.isLoading = true;
                axios({
                    url: `${this.endpoint}/${this.$route.params.id}`,
                    data: formData,
                    method: "PUT"
                })
                    .then(({ data }) => {
                        this.isLoading = false;
                        this.formData.status = data.status
                        this.$notify({
                            title: "Success",
                            text: statusMessage[formData.status],
                            type: "successs"
                        });
                    })
                    .catch((error) => {
                        this.$notify({
                            title: "Error",
                            text: error.response ? error.response.data.errors.message : "Error",
                            type: "error"
                        });
                        return false;
                    })
                    .finally((result) => {
                        this.isLoading = false;
                        return result;
                    })
            }
        }
    }
}
</script>

<style lang="scss">
.leads {
    button:not(.btn-normal) {
        padding: 4px 15px;
        border-radius: 10px;
        font-family: "Roboto";
        font-size: 11px;
    }

    .client-name {
        font-family: 'Open Sans', sans-serif;

        h2 {
            font-size: 28px;
            font-weight: 300;
            margin-bottom: 5px;
        }

        span {
            display: block;
            font-size: 12px;

            strong {
                font-weight: 600;
                margin-left: 10px;
            }
        }
    }

    .actions {
        display: flex;

        button {
            margin-top: 0;
            font-size: 14px;
            padding: 3px 50px;
            border-radius: 5px;

            &:last-child {
                margin-left: 10px;
            }
        }
    }

    .vue-tabs {
        .nav-tabs-navigation {
            .nav-tabs-wrapper {
                .nav.nav-tabs {
                    display: flex;
                    justify-content: space-between;

                    li {
                        a {
                            padding: 15px;
                            font-size: 14px;
                            color: #383752;

                            span {
                                display: flex;
                                align-items: center;

                                i {
                                    margin-right: 8px;
                                }
                            }

                            &:hover {
                                background-color: transparent;
                                border-color: transparent;
                            }
                        }

                        &.active {
                            .active_tab {
                                background-color: transparent;
                                border: 0;
                                color: var(--secondary-color);
                            }
                        }
                    }
                }
            }
        }

        .tab-content {
            min-height: 68px;
        }
    }
}
</style>
